.box-content {
  width: 420px;
  margin: auto;
  padding: 35px 35px 30px;
  background-color: #fff;
  /* -o-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25); */
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  font-size: 14px;
  max-width: 94%;
  text-align: center;
}
.MuiSelect-icon {
  color: #757575 !important;
}

.apexcharts-tooltip.apexcharts-theme-dark {
  background: transparent !important;
}
.apexcharts-tooltip {
  box-shadow: 2px 2px 6px 4px transparent !important;
}
.apexcharts-tooltip.apexcharts-theme-light {
  border: 0px solid transparent !important;
  background: transparent !important;
}
.arrow_box {
  margin: 10px;
  padding: 10px;
  position: relative;
  background: #545454;
  /* border: 2px solid #000000; */
  border-radius: 4px;
}

.arrow_box:after,
.arrow_box:before {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* .arrow_box:after {
  border-color: rgba(85, 85, 85, 0);
  border-right-color: #555;
  border-width: 10px;
  margin-top: -10px;
}
.arrow_box:before {
  border-color: rgba(0, 0, 0, 0);
  border-right-color: #000000;
  border-width: 13px;
  margin-top: -13px;
} */
/* .arrow_box:after,
.arrow_box:before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow_box:after {
  border-color: rgba(85, 85, 85, 0);
  border-right-color: #555;
  border-width: 10px;
  margin-top: -10px;
}
.arrow_box:before {
  border-color: rgba(0, 0, 0, 0);
  border-right-color: #000000;
  border-width: 13px;
  margin-top: -13px;
} */

.blueDot {
  position: relative;
}
.blueDot::after {
  content: '';
  position: absolute;
  top: 5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #276ef1;
  border: solid 1px white;
}

.lightBlueDot {
  position: relative;
}
.lightBlueDot::after {
  content: '';
  position: absolute;
  top: 5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #6f9ef6;
  border: solid 1px white;
}

.moreLightBlueDot {
  position: relative;
}
.moreLightBlueDot::after {
  content: '';
  position: absolute;
  top: 5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #c2d5f9;
  border: solid 1px white;
}
.textBlueDot {
  margin-left: 15px;
}

.percentageText {
  text-align: center;
  font-size: 10px;
}

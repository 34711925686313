.fieldsRoot {
  margin: 16px 16px 16px 0px;
}
.fieldSmall {
  margin: 8px 16px 8px 0px;
}
.fieldslabel {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #5d5d5d;
}
.fieldsValue {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  color: #5d5d5d;
  white-space: pre-wrap;
  word-wrap: break-word;
  /* width: 200px; */
}
